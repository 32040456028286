<template>
  <div id="demo"></div>
</template>

<script setup>
import Pdfh5 from 'pdfh5';
import {
  onMounted
} from 'vue';
// import { useRoute } from 'vue-router'
// const route = useRoute();
onMounted(() => {
  const currentUrl = window.location.href;
  const params_part = currentUrl.split('?')[1]
  const url_param = params_part.split('=')[1]
  new Pdfh5('#demo', {
    pdfurl: url_param
  });
});
</script>

<style>
@import 'pdfh5/css/pdfh5.css';


#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#demo {}

* {
  padding: 0;
  margin: 0;
}

html,
body,
#app {
  width: 100%;
  height: 100%;
}
</style>
